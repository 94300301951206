import React, { useState } from "react";
import "../styles/login.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
function Login({ setIsLoggedIn }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const submit = async () => {
    try {
      console.log(email, " ", password, " ");
      if (!email || email.trim().length < 5) {
        toast.error("Email is required");
        return;
      }

      if (!password || password.trim().length < 5) {
        toast.error("password is required");
        return;
      }
      const requestBody = {
        email: email,
        password: password,
      };

      const url = process.env.REACT_APP_URL;
      setIsLoading(true);
      const response = await fetch(`${url}auth/admin/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      console.log(response.ok);

      if (response.ok === false) {
        throw new Error("Failed to fetch");
      }

      const data = await response.json();
      const access_token = data.data.access_token;
      localStorage.setItem("access_token", access_token);

      setIsLoading(false);

      setIsLoggedIn(true);
      navigate("/");
    } catch (err) {
      console.log(err);
      setIsLoading(false);

      toast.error("Login failed");
    }
  };

  return (
    <div className="login-parent">
      <div className="login-body">
        <div className="login-title">Login</div>
        {/* <div className="input-switch">
          <div className="login-subtitle">PROD</div>

          <label class="switch">
            <input
              type="checkbox"
              class="checkbox"
              value={isQA}
              onChange={(e) => setIsQA(e.target.checked)}
            />
            <div class="slider"></div>
          </label>
          <div className="login-subtitle">QA</div>
        </div> */}

        <div className="input-distance">
          <input
            type="email"
            placeholder="Your email"
            className="login-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Your password"
            className="login-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <button className="login-button " onClick={submit} disabled={isLoading}>
          Login
        </button>
      </div>
    </div>
  );
}

export default Login;
