// NotFoundPage.js

import React from "react";

const NotFoundPage = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404 - Page Not Found</h1>
      <p style={styles.paragraph}>
        The page you are looking for does not exist.
      </p>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    marginTop: "100px",
  },
  heading: {
    fontSize: "36px",
    color: "#ff6347", // Red color
  },
  paragraph: {
    fontSize: "18px",
    color: "#fff", // Dark gray color
  },
};

export default NotFoundPage;
