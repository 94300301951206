import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import NotFoundPage from "./pages/NotFoundPage";
import Login from "./pages/Login";
import { CircularProgress } from "@mui/material";
import Home from "./pages/Home";
import { ToastContainer } from "react-toastify";
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");

    if (accessToken && accessToken !== undefined) {
      setIsLoggedIn(true);
    }
    setIsLoading(false);
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      {!isLoading ? (
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                isLoggedIn ? (
                  <Home setIsLoggedIn={setIsLoggedIn} />
                ) : (
                  <Navigate to="/login" setIsLoggedIn={setIsLoggedIn} />
                  // <Login setIsLoggedIn={setIsLoggedIn} />
                )
              }
            />
            <Route
              path="/login"
              element={
                isLoggedIn ? (
                  <Navigate to="/" />
                ) : (
                  <Login setIsLoggedIn={setIsLoggedIn} />
                )
              }
            />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      ) : (
        <div>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default App;
