import React, { useEffect, useRef, useState } from "react";
import "../styles/home.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { TablePagination } from "@mui/base";
import { CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import moment from "moment";
function ProductTable({ selectUser, user }) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const bottomRef = useRef();
  useEffect(() => {
    fetchData(0);
  }, [user]);

  useEffect(() => {
    if (user && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [user]);

  const fetchData = async (pageNo) => {
    try {
      setIsLoading(true);
      const accessToken = localStorage.getItem("access_token");
      const url = process.env.REACT_APP_URL;

      const response = await fetch(
        `${url}admin/products?pageNo=${pageNo + 1}&userID=${user}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Err occured");
      }
      const data = await response.json();
      console.log(data.data.products.length);
      console.log("data here");
      setProducts(data.data.products);
      setTotalProducts(data.data.count);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error occured");
    }
  };

  const handleChangePage = (event, newPage) => {
    fetchData(newPage);

    setPage(newPage);
  };

  return (
    <div>
      <div className="product-close" ref={bottomRef}>
        <div className="stats">Products</div>
        {/* <div className="close">X</div> */}
        <IconButton onClick={() => selectUser(null)} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Title</StyledTableCell>
                <StyledTableCell>Price</StyledTableCell>
                <StyledTableCell>Quality</StyledTableCell>
                <StyledTableCell>Category</StyledTableCell>
                <StyledTableCell>Age</StyledTableCell>
                <StyledTableCell>createdAt</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              ) : (
                products.map((row) => (
                  <StyledTableRow
                    key={row.id}
                    // className="row-pointer"
                    // onClick={() => selectUser(null)}
                  >
                    <StyledTableCell component="th" scope="row">
                      {row.title}
                    </StyledTableCell>
                    <StyledTableCell>{row.price}</StyledTableCell>
                    <StyledTableCell>{row.quality}</StyledTableCell>
                    <StyledTableCell>{row.category}</StyledTableCell>
                    <StyledTableCell>{row.age}</StyledTableCell>

                    <StyledTableCell>
                      {moment.utc(row.createdAt).format("DD-MM-YYYY")}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalProducts}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[]}
          rowsPerPage="10"
        />
      </div>
    </div>
  );
}

export default ProductTable;
