import React, { useState } from "react";
import "../styles/home.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { TablePagination } from "@mui/base";
import { CircularProgress } from "@mui/material";
import moment from "moment";
function UsersTable({ users, setUsers, totalUsers, selectUser }) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async (newPage) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      console.log("first");
      const url = process.env.REACT_APP_URL;
      setIsLoading(true);

      const response = await fetch(`${url}admin/users?pageNo=${newPage + 1}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch");
      }

      const data = await response.json();
      console.log(data.data.users.length);
      setUsers(data.data.users);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setIsLoading(true);

    console.log(newPage);
    fetchData(newPage);

    setPage(newPage);
  };

  return (
    <div>
      <div className="stats">Users</div>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>BankID</StyledTableCell>
                <StyledTableCell>TotalSwipes</StyledTableCell>
                <StyledTableCell>RightSwipes</StyledTableCell>
                <StyledTableCell>CreatedDate</StyledTableCell>
                <StyledTableCell>LeftSwipes</StyledTableCell>
                <StyledTableCell>Products</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                users.map((row) => (
                  <StyledTableRow
                    key={row.id}
                    className="row-pointer"
                    onClick={() => {
                      selectUser(row.id);
                    }}
                  >
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell>{row.email}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.bankIDVerified ? "Yes" : "No"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.totalSwipes}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {" "}
                      {row.rightSwipe}
                    </StyledTableCell>
                    <StyledTableCell>
                      {moment.utc(row.createdAt).format("DD-MM-YYYY")}
                    </StyledTableCell>
                    <StyledTableCell>{row.leftSwipe}</StyledTableCell>
                    <StyledTableCell>{row.totalProducts}</StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalUsers}
          rowsPerPageOptions={[]}
          rowsPerPage="10"
          page={page}
          onPageChange={handleChangePage}
        />
      </div>
    </div>
  );
}

export default UsersTable;
