import React, { useEffect, useState } from "react";
import "../styles/filter.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { TablePagination } from "@mui/base";
import { CircularProgress } from "@mui/material";
import moment from "moment";

function Products() {
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [val, setVal] = useState("latest");

  const [users, setUsers] = useState();
  const [totalUser, setTotalUser] = useState(0);

  useEffect(() => {
    setIsLoading(true);

    fetchData(0);
  }, [val]);

  const fetchData = async (newPage) => {
    try {
      console.log(val);

      setIsDataLoading(true);
      const accessToken = localStorage.getItem("access_token");
      console.log("first");
      const url = process.env.REACT_APP_URL;
      const response = await fetch(
        `${url}admin/random-products?pageNo=${newPage + 1}&filter=${val}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch");
      }

      const data = await response.json();
      console.log(data.data.products);
      setUsers(data.data.products);
      setTotalUser(data.data.count);
      setIsLoading(false);
      setIsDataLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="product-padding">
      <div className="notification-title">Products</div>

      {isLoading && (
        <div className="circular-bar-flex">
          <CircularProgress />
        </div>
      )}
      {users && !isLoading && users.length > 0 && (
        <div>
          <UsersTable
            users={users}
            totalUsers={totalUser}
            fetchData={fetchData}
            isLoading={isDataLoading}
            val={val}
            setVal={setVal}
          />
        </div>
      )}

      {users && users.length === 0 && <div>No data available</div>}
    </div>
  );
}

function UsersTable({ users, totalUsers, fetchData, isLoading, val, setVal }) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const TruncatedTableCell = styled(StyledTableCell)({
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "150px", // Adjust max-width as needed
  });

  const WrappedTableCell = styled(StyledTableCell)({
    wordWrap: "break-word",
    maxWidth: "150px", // Adjust max-width as needed
  });

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    fetchData(newPage);
    setPage(newPage);
  };

  return (
    <div>
      <div className="input-distance">
        <div className="product-input-sort">
          <div>Sort by</div>
          <select
            name="type"
            id="type"
            className="input-type2"
            value={val}
            onChange={(e) => setVal(e.target.value)}
          >
            <option value="latest">Latest</option>
            <option value="likes">Likes</option>
            <option value="views">Views</option>
          </select>
        </div>
        <div className="table-container">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Price</StyledTableCell>
                  <StyledTableCell>Owner</StyledTableCell>
                  <StyledTableCell>Images</StyledTableCell>
                  <StyledTableCell>Description</StyledTableCell>
                  <StyledTableCell>Likes</StyledTableCell>
                  <StyledTableCell>Views</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  users.map((row) => (
                    <StyledTableRow key={row.id}>
                      <WrappedTableCell>{row.title}</WrappedTableCell>
                      <StyledTableCell>{row.price}</StyledTableCell>
                      <StyledTableCell>
                        <div>
                          <div>{row.owner.name}</div>
                          <div>{row.owner.email}</div>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="images-vertical">
                          {row.files.map((file, i) => (
                            <img
                              src={file.fileURL}
                              alt="file"
                              key={i}
                              className="image-class"
                            />
                          ))}
                        </div>
                      </StyledTableCell>
                      <WrappedTableCell>{row.description}</WrappedTableCell>
                      <StyledTableCell>{row.likes}</StyledTableCell>
                      <StyledTableCell>{row.views}</StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            rowsPerPage={10}
            component="div"
            count={totalUsers}
            page={page}
            onPageChange={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
}

export default Products;
