import React, { useEffect, useState } from "react";
import "../styles/home.css";

import { CircularProgress } from "@mui/material";
import Notification from "../components/Notification";
import TopTable from "../components/TopTable";
import UsersTable from "../components/UsersTable";
import ProductTable from "../components/ProductTable";
import { useNavigate } from "react-router";
import { Logout } from "@mui/icons-material";
import FilterUsers from "./FilterUsers";
import Products from "../pages/Products";

function Home({ setIsLoggedIn }) {
  const [active, setActive] = useState(1);
  const [stats, setStats] = useState({});
  const [page, setPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [user, selectUser] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
  }, []); // Fetch data when page or pageSize changes

  const fetchData = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      console.log("first", process.env.REACT_APP_URL);
      const url = process.env.REACT_APP_URL;

      console.log(url);
      setIsLoading(true);
      const response = await fetch(`${url}admin/users?pageNo=${page}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 401) {
        localStorage.clear("access_token");
        localStorage.clear("isQA");

        setIsLoggedIn(false);
        return;
      }

      const data = await response.json();

      console.log(data.data.users.length);
      setStats(data.data.details);
      setTotalUsers(data.data.totalUser);
      setUsers(data.data.users);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const logout = async () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("isQA");
    setIsLoggedIn(false);
    navigate("/login");
  };

  return (
    <div className="home">
      <div className="home-design">
        <div className="home-design-menu">
          <div>
            <div className="swopper-title-align">
              <div className="swopper-title">Swopper</div>
            </div>

            <div
              className={`home-design-item ${active === 1 && "active"}`}
              onClick={() => setActive(1)}
            >
              Home
            </div>

            <div
              className={`home-design-item ${active === 2 && "active"}`}
              onClick={() => setActive(2)}
            >
              Notification
            </div>

            <div
              className={`home-design-item ${active === 3 && "active"}`}
              onClick={() => setActive(3)}
            >
              Filter Users
            </div>

            <div
              className={`home-design-item ${active === 4 && "active"}`}
              onClick={() => setActive(4)}
            >
              Products
            </div>
          </div>
          <div className="logout-pane" onClick={() => logout()}>
            <Logout className="logout-icon" />
            <div className="logout-text">Logout</div>
          </div>
        </div>
        {active === 1 && (
          <div className="home-design-page">
            {!isLoading && (
              <div className="overflow-auto">
                <div className="home-design-top">
                  <TopTable data={stats} />
                </div>
                <div className="home-design-top">
                  <UsersTable
                    users={users}
                    setUsers={setUsers}
                    totalUsers={totalUsers}
                    selectUser={selectUser}
                  />
                </div>
                {user && (
                  <div className="home-design-top">
                    <ProductTable user={user} selectUser={selectUser} />
                  </div>
                )}
              </div>
            )}

            {isLoading && (
              <div className="circular-bar-flex">
                <CircularProgress />
              </div>
            )}
          </div>
        )}

        {active === 2 && <Notification />}
        {active === 3 && <FilterUsers />}
        {active === 4 && <Products />}
      </div>
    </div>
  );
}

export default Home;
