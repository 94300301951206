import React, { useEffect, useState } from "react";
import "../styles/filter.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { TablePagination } from "@mui/base";
import { CircularProgress } from "@mui/material";
import moment from "moment";
function FilterUsers() {
  const [startDate, setStartDate] = useState("2022-02-22");
  const [endDate, setEndDate] = useState("2024-07-22");
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [users, setUsers] = useState();
  const [totalUser, setTotalUser] = useState(0);
  const [sortType, setSortType] = useState("ASC");

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zero if month or day is less than 10
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    setIsLoading(true);
    setEndDate(`${year}-${month}-${day}`);
    fetchData(0);
  }, []);

  const fetchData = async (newPage) => {
    try {
      setIsDataLoading(true);
      const accessToken = localStorage.getItem("access_token");
      console.log("first");
      const url = process.env.REACT_APP_URL;
      console.log(startDate);
      const response = await fetch(
        `${url}admin/filter-user?pageNo=${
          newPage + 1
        }&sortOrder=${sortType}&fromDate=${startDate}&toDate=${endDate}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch");
      }

      const data = await response.json();
      console.log(data.data.users);
      setUsers(data.data.users);
      setTotalUser(data.data.totalCount);
      setIsLoading(false);
      setIsDataLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="notification">
      <div className="notification-title">Filter users</div>

      <div className="filter-param">
        <div className="date-select">
          <label className="label">Start date:</label>

          <input
            type="date"
            id="start"
            name="trip-start"
            value={startDate}
            disabled={isLoading}
            onChange={(e) => setStartDate(e.target.value)}
            min="2018-01-01"
            max={endDate}
            className="input-type"
          />
        </div>

        <div className="date-select">
          <label className="label">End date:</label>

          <input
            type="date"
            id="end"
            name="trip-start"
            value={endDate}
            disabled={isLoading}
            onChange={(e) => setEndDate(e.target.value)}
            min="2018-01-01"
            max={endDate}
            className="input-type"
          />
        </div>

        <div className="date-select">
          <label className="label">Sort by:</label>
          <select
            name="type"
            id="type"
            className="input-type"
            value={sortType}
            disabled={isLoading}
            onChange={(e) => setSortType(e.target.value)}
          >
            <option value="ASC">Ascending</option>
            <option value="DESC">Descending</option>
          </select>
        </div>

        <button
          className="search-button"
          disabled={isLoading}
          onClick={() => fetchData(0)}
        >
          {"Search"}
        </button>
      </div>

      {isLoading && (
        <div className="circular-bar-flex">
          <CircularProgress />
        </div>
      )}
      {users && !isLoading && users.length > 0 && (
        <div>
          <UsersTable
            users={users}
            totalUsers={totalUser}
            fetchData={fetchData}
            isLoading={isDataLoading}
          />
        </div>
      )}

      {users && users.length === 0 && <div>No data available</div>}
    </div>
  );
}

function UsersTable({ users, totalUsers, fetchData, isLoading }) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    fetchData(newPage);

    setPage(newPage);
  };

  return (
    <div>
      <div className="input-distance">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>BankId Verified</StyledTableCell>
                <StyledTableCell>Login type</StyledTableCell>
                <StyledTableCell>referID</StyledTableCell>
                <StyledTableCell>createdAt</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                users.map((row) => (
                  <StyledTableRow
                    key={row.id}
                    className="row-pointer"
                    onClick={() => {}}
                  >
                    <StyledTableCell component="th" scope="row">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell>{row.email}</StyledTableCell>
                    <StyledTableCell>
                      {row.bankIDVerified ? "Yes" : "No"}
                    </StyledTableCell>
                    <StyledTableCell>{row.loginType}</StyledTableCell>
                    <StyledTableCell>{row.referID}</StyledTableCell>
                    <StyledTableCell>
                      {moment.utc(row.createdAt).format("DD-MM-YYYY")}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          rowsPerPage="10"
          component="div"
          count={totalUsers}
          page={page}
          onPageChange={handleChangePage}
        />
      </div>
    </div>
  );
}

export default FilterUsers;
