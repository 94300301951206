import React, { useEffect, useState } from "react";
import "../styles/notification.css";
import { toast } from "react-toastify";
import {
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { TextField } from "@mui/material";
import "moment-timezone";

function Notification() {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [number, setNumber] = useState();
  const [selectedVal, setSelectedVal] = useState("like");
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState();
  const [selectedDateTime, setSelectedDateTime] = useState();
  const [notifications, setNotifications] = useState([]);

  const sendNotifi = async () => {
    try {
      if (users && (!title || title.trim().length < 1)) {
        toast.error("Title is required");
        return;
      }

      if (users && (!description || description.trim().length < 1)) {
        toast.error("Description is required");
        return;
      }
      if (!number) {
        toast.error("number is required");
        return;
      }

      if (number < 1) {
        toast.error("Number should be greater than 0");
        return;
      }

      if (!/^\d+$/.test(number)) {
        toast.error("Invalid number");
        return;
      }

      setIsLoading(true);
      const requestBody = {
        title: title || "title",
        description: description || "desc",
        status: selectedVal,
        notificationDays: parseInt(number),
      };

      if (selectedDateTime) {
        requestBody["notifyTime"] = selectedDateTime.toISOString();
      }

      console.log(requestBody);

      const accessToken = localStorage.getItem("access_token");
      console.log("first");
      const url = process.env.REACT_APP_URL;

      if (users && users.length > 0) {
        const response = await fetch(`${url}admin/send-notification`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,

            // Add any other headers if needed
          },
          body: JSON.stringify(requestBody),
        });
        if (!response.ok) {
          throw new Error("Failed to fetch");
        }

        toast.success("Notification sent");
        loadNotification();
      } else {
        const response = await fetch(`${url}admin/all-noti-users`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,

            // Add any other headers if needed
          },
          body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        console.log(data);
        setUsers(data.data.users);
        if (!response.ok) {
          throw new Error("Failed to fetch");
        }
      }

      setIsLoading(false);
    } catch (err) {
      toast.error("Unable to send notification");
      setIsLoading(false);

      console.log(err);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleDateTimeChange = (newDateTime) => {
    setSelectedDateTime(newDateTime);
    console.log(newDateTime);
  };

  useEffect(() => {
    loadNotification();
  }, []);

  const loadNotification = async () => {
    try {
      setIsLoading(true);
      const accessToken = localStorage.getItem("access_token");
      console.log("first");
      const url = process.env.REACT_APP_URL;

      const response = await fetch(`${url}notification/admin`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const result = await response.json();
      setNotifications(result.data.notifications);
    } catch (err) {
      console.log(err);
      toast.error("Unable to load notification");
    }
    setIsLoading(false);
  };

  return (
    <div className="notification">
      <div className="">
        <div className="notification-flex-1">
          <div className="notification-title">Send Notification</div>
          {!isLoading && (
            <div className="input-form">
              <div className="input-form-flex">
                <SelectInput
                  val={selectedVal}
                  setVal={setSelectedVal}
                  disabled={users}
                />
                <InputEnter
                  title="less than"
                  placeholder={"Input a number"}
                  val={number}
                  setVal={setNumber}
                  type="number"
                  disabled={users}
                />
              </div>
              {users && (
                <>
                  <InputEnter
                    title="Title"
                    placeholder={"Notification title"}
                    val={title}
                    setVal={setTitle}
                    type="text"
                  />
                  <InputEnter
                    title="Description"
                    placeholder={"Notification description"}
                    val={description}
                    setVal={setDescription}
                    type="text"
                  />

                  <div className="input-distance">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DateTimePicker"]}>
                        <DateTimePicker
                          value={selectedDateTime}
                          label="Schedule notification time"
                          onAccept={handleDateTimeChange}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </>
              )}

              <div className="button-flex">
                <button
                  className="button"
                  onClick={() => sendNotifi()}
                  disabled={isLoading}
                >
                  {users ? "Send Notification" : "Get users"}
                </button>
                {users && (
                  <button className="reset-button" onClick={() => setUsers()}>
                    Reset
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        {!isLoading && (
          <div className="notification-flex-2 noti-margin">
            <div className="notification-title">Scheduled Notifications</div>
            <div>
              <BasicTable rows={notifications} />
            </div>
          </div>
        )}
      </div>

      {isLoading && (
        <div className="circular-bar-flex">
          <CircularProgress />
        </div>
      )}
      {users && (
        <div className="input-distance-1">Total users: {users.length}</div>
      )}
      {users && users.length > 0 && (
        <div className="notification-users">
          <div className="user-flex">
            <div className="stats">Users</div>
            <IconButton onClick={() => setUsers(null)} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>bankIDVerified</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell>{row.email}</StyledTableCell>
                      <StyledTableCell>
                        {row.bankIDVerified ? "Yes" : "No"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
    </div>
  );
}

function InputEnter({ title, placeholder, val, setVal, type, disabled }) {
  return (
    <div className="input-distance">
      <div className="input-group">
        <label className="label">{title}</label>
        <input
          value={val}
          onChange={(e) => setVal(e.target.value)}
          required={true}
          autocomplete="off"
          className="input"
          type={type}
          placeholder={placeholder}
          disabled={disabled}
        />
        <div></div>
      </div>
    </div>
  );
}

function BasicTable({ rows }) {
  console.log("rows", rows);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell align="left">Desc</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Notification sent</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.length > 0 &&
            rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.title}
                </TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>
                  {moment
                    .utc(row.notifyTime)
                    .tz("Europe/Oslo")
                    .format("DD.MM.YYYY HH:mm")}
                </TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.totalUsersSent}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function SelectInput({ val, setVal, disabled }) {
  return (
    <div className="input-distance">
      <div className="input-group">
        <label className="label">Notification type </label>
        <select
          name="type"
          id="type"
          disabled={disabled}
          className="input-type"
          value={val}
          onChange={(e) => setVal(e.target.value)}
        >
          <option value="like">Likes</option>
          <option value="product">Products</option>
          <option value="join">Join date</option>
        </select>
      </div>
    </div>
  );
}

export default Notification;
